<script setup lang="ts">
const formData = {
  firstName: "",
  lastName: "",
  email: "",
  company: "",
  message: "",
  optIn: false,
};

interface FormErrors {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  message: boolean;
}

const formErrors = ref<FormErrors>({
  firstName: false,
  lastName: false,
  email: false,
  message: false,
});

const hasSubmitted = ref(false);

function checkForm(): void {
  let hasErrors = false;

  if (formData.firstName == "") {
    formErrors.value.firstName = true;
  } else {
    formErrors.value.firstName = false;
  }

  if (formData.lastName == "") {
    formErrors.value.lastName = true;
  } else {
    formErrors.value.lastName = false;
  }

  if (!validEmail(formData.email)) {
    formErrors.value.email = true;
  } else {
    formErrors.value.email = false;
  }

  if (formData.message == "") {
    formErrors.value.message = true;
  } else {
    formErrors.value.message = false;
  }

  for (const [key, value] of Object.entries(formErrors.value)) {
    console.log(`${key}: ${value}`);
    if (value) {
      hasErrors = true;
    }
  }

  if (!hasErrors) {
    submitForm();
  }
}

async function submitForm() {
  let body =
    "The following request for information was automatically generated by the zenapptic.ai website.<br><br>";
  body += `<div style='font-weight:bold;display:block'>Name:</div><div>${formData.firstName} ${formData.lastName}</div><br>`;
  body += `<div style='font-weight:bold;display:block'>Company:</div><div>${formData.company}</div><br>`;
  body += `<div style='font-weight:bold;display:block'>Email:</div><div>${formData.email}</div><br>`;
  body += `<div style='font-weight:bold;display:block'>Receive notifications:</div><div>${formData.optIn}</div><br>`;
  body += `<div style='font-weight:bold;display:block'>Message:</div><div>${formData.message}</div><br>`;

  let url =
    "https://zen-bridge.azurewebsites.net/api/Zen?function=MSGraphEmail&action=infoRequest&type=info";

  url += `&subject=Zenapptic website info request&bodyType=HTML&body=${encodeURIComponent(body)}`;

  await fetch(url);
}

function validEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
</script>

<template>
  <div class="home-section contact-block">
    <div class="container">
      <h2>Contact <b>Us</b></h2>

      <div v-if="!hasSubmitted" class="contact-form">
        <form>
          <div class="form-field">
            <label for="firstName" class="sr-only">First name</label>
            <input
              id="firstName"
              v-model="formData.firstName"
              type="text"
              name="firstName"
              placeholder="First name"
              required
            />
            <p v-if="formErrors.firstName" class="invalid">* Please enter your first name</p>
          </div>
          <div class="form-field">
            <label for="lastName" class="sr-only">Last name</label>
            <input
              id="lastName"
              v-model="formData.lastName"
              type="text"
              name="lastName"
              placeholder="Last name"
              required
            />
            <p v-if="formErrors.lastName" class="invalid">* Please enter your last name</p>
          </div>
          <div class="form-field">
            <label for="email" class="sr-only">Work email</label>
            <input
              id="email"
              v-model="formData.email"
              type="email"
              name="email"
              placeholder="Work email"
              required
            />
            <p v-if="formErrors.email" class="invalid">* Please enter a valid email</p>
          </div>
          <div class="form-field">
            <label for="company" class="sr-only">Company</label>
            <input
              id="company"
              v-model="formData.company"
              type="text"
              name="company"
              placeholder="Company"
            />
          </div>
          <div class="form-field span-2">
            <label for="message" class="sr-only">Tell us about your project</label>
            <textarea
              id="message"
              v-model="formData.message"
              name="message"
              placeholder="Tell us about your project"
              required
            ></textarea>
            <p v-if="formErrors.message" class="invalid">
              * Please provide us details of how we can help you
            </p>
          </div>

          <div class="disclaimers span-2">
            <p>
              Zenapptic will only use your personal information to provide the services you
              requested from us. We would like to contact you about our services, as well as other
              content that may be of interest to you. You may unsubscribe from this communication at
              any time.
            </p>

            <div class="option">
              <input id="optIn" v-model="formData.optIn" type="checkbox" name="optIn" />
              <label for="optIn"
                >I agree to receive informational and marketing communications from
                Zenapptic.</label
              >
            </div>
          </div>
        </form>

        <div class="span-2 submit-block">
          <TheCtaButton href="#" @click="checkForm"> SEND </TheCtaButton>
        </div>
      </div>

      <div v-if="hasSubmitted" class="submit-response">
        <h3>Thanks for getting in touch!</h3>
        <p>We will respond to your request as soon as we can!</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "../assets/scss/vars";
.contact-block {
  background-color: #1a1719;
  background-image: url(/assets/backgrounds/contact-background.webp);
  color: #fff;

  .container {
    h2 {
      font-weight: 200;

      @media only screen and (max-width: 768px) {
        font-size: min(7vw, 4rem);
        margin: 0;
      }
    }
    .body {
      p {
        background: url(../assets/dots.png) no-repeat left 1.3rem;
        color: vars.$primary-color;
        margin-top: 6rem;
        padding-left: 1.3rem;

        @media only screen and (max-width: 768px) {
          margin-top: 2rem;
        }
      }
    }

    .body,
    .contact-form {
      font-size: min(4vw, 2.6rem);
    }

    .contact-form {
      margin: auto;
      max-width: 700px;
      width: 55%;

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      form {
        display: grid;
        grid-row-gap: 3rem;

        @media only screen and (min-width: 769px) {
          grid-column-gap: 4rem;
          grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width: 768px) {
          display: block;
        }

        .form-field {
          @media only screen and (max-width: 768px) {
            margin: 2rem 1rem;
          }

          input,
          textarea {
            color: #666;
            font-family: "pragmatica", sans-serif;
            font-size: 2rem;
            padding: 0.5rem 1rem;
            width: 100%;
          }

          textarea {
            min-height: 12rem;
          }

          .invalid {
            font-size: 1.5rem;
            margin: 0.3rem 0;
          }
        }

        .disclaimers {
          display: flex;
          flex-direction: column;
          gap: 3rem;

          @media only screen and (max-width: 768px) {
            gap: 1rem;
          }

          p,
          label {
            color: #fff;
            font-size: min(4vw, 1.8rem);
            margin: 0;
          }

          p {
            @media only screen and (max-width: 768px) {
              margin: 2rem 1rem;
            }
          }

          .option {
            display: flex;
            gap: 1rem;
          }
        }
      }

      .submit-block {
        padding-top: 3rem;
        text-align: center;
      }
    }

    .submit-response {
      padding: 7rem;
      text-align: center;
      h3 {
        font-size: 2.4rem;
      }
      p {
        font-size: 2rem;
      }
    }

    .span-2 {
      grid-column: 1 / span 2;
    }
  }
}
</style>
